/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import ContentPageTemplate from "../../templates/content-page";
const MDXLayout = ContentPageTemplate;
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "EMDR – Traumatherapie"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "EMDR"), " (Eye Movement Desensitization and Reprocessing) ist eine wissenschaftlich anerkannte psychotherapeutische Methode zur Behandlung von posttraumatischen Belastungsstörungen. EMDR kann zuweilen auch hilfreich sein, um belastende Erinnerungen zu verarbeiten, die z. B. die Besserung einer Depression verhindern."), "\n", React.createElement(_components.p, null, "EMDR kann innerhalb einer genehmigten Psychotherapie jedes Verfahrens (Verhaltenstherapie, tiefenpsychologische Psychotherapie, Psychoanalyse) angewandt werden. Eine EMDR-Behandlung erfolgt stets als Einzelbehandlung."), "\n", React.createElement(_components.p, null, "In unserer Praxis wenden wir EMDR stets in einem gemeinsam mit Ihnen erarbeiteten Behandlungskonzept an."), "\n", React.createElement(_components.p, null, "Dabei gilt der Grundsatz, dass Stabilisierung Vorrang hat vor Traumabearbeitung."), "\n", React.createElement(_components.p, null, "Die Erarbeitung positiver Ressourcen, die helfen, den Belastungen, auch den Belastungen der Behandlung, besser begegnen und im Alltag besser bestehen zu können, nimmt ebenfalls einen wichtigen Platz ein."));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
